import React from 'react';

import Layout from '../../components/Layout';
import PricingTable from '../../components/PricingTable';

import pic0 from '../../assets/images/services/software-training.png';
import pic1 from '../../assets/images/courses/aws-certified-dev.png';
import pic2 from '../../assets/images/courses/aws-solutions-architect-associate.png';
import pic3 from '../../assets/images/courses/backend-dev.jpg';
import pic4 from '../../assets/images/courses/BusinessAnalyst-Training.png';
import pic5 from '../../assets/images/courses/DevOps-Engineer-Training.jpg';
import pic6 from '../../assets/images/courses/frontend-dev.jpg';
import pic7 from '../../assets/images/courses/full-stack-developer-training.jpg';
import pic8 from '../../assets/images/courses/mobile-app-developer-training.png';
import pic9 from '../../assets/images/courses/software-Testing-Training.jpg';

import { Link } from 'gatsby';


const SoftwareTrngPage = () => (
  <Layout>
    <div id="main">
      <div className="inner box" align="center">
        <header>

          <h1>Software Training</h1>
          <span className="image main">
            <img src={pic0} alt="" />
          </span>
        </header>
        <p align="justify"><br />
            We offer a range of different courses that are suitable for the various areas of IT projects. 
            Our courses are useful for freshers as well as experienced professionals looking to learn new technologies for career enhancement.
            We have a well designed comprehenesive program structure that uses both theory and hands-on exercises to help you get the best training.
            
        </p>
        <div className="inner box" align="center">
        <section className="tiles">
          <article className="style1">
            <span className="image">
              <img src={pic1} alt="" />
            </span>
            <Link to="/courses/AwsDevAssociateTrng">
              <h2>AWS Developer Associate</h2>
              <div className="content">
                <p>
                  This is our comprehensive AWS Certified Developer Associate training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style2">
            <span className="image">
              <img src={pic2} alt="" />
            </span>
            <Link to="/courses/AwsSolArchAssociateTrng">
              <h2>AWS Solution Architect Associate</h2>
              <div className="content">
                <p>
                  This is our comprehensive AWS Certified Solution Architect Associate training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style1">
            <span className="image">
              <img src={pic7} alt="" />
            </span>
            <Link to="/courses/FullStackDevTrng">
              <h2>FullStack Developer </h2>
              <div className="content">
                <p>
                This is our comprehensive FullStack Developer training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style4">
            <span className="image">
              <img src={pic4} alt="" />
            </span>
            <Link to="/courses/BusinessAnalystTrng">
              <h2>Business Analyst </h2>
              <div className="content">
                <p>
                This is our comprehensive Business Analyst training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style2">
            <span className="image">
              <img src={pic8} alt="" />
            </span>
            <Link to="/courses/MobileAppDevTrng">
              <h2>Mobile App Developer </h2>
              <div className="content">
                <p>
                This is our comprehensive Mobile App Developer Training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style4">
            <span className="image">
              <img src={pic9} alt="" />
            </span>
            <Link to="/courses/SoftwareTestingTrng">
              <h2>Software Testing  </h2>
              <div className="content">
                <p>
                This is our comprehensive Software Testing Training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style6">
            <span className="image">
              <img src={pic6} alt="" />
            </span>
            <Link to="/courses/FrontEndDevTrng">
              <h2>FrontEnd Developer </h2>
              <div className="content">
                <p>
                This is our comprehensive FrontEnd Developer training.
                </p>
              </div>
            </Link>
          </article>
          <article className="style4">
            <span className="image">
              <img src={pic3} alt="" />
            </span>
            <Link to="/courses/BackEndDeveloperTrng">
              <h2>Backend Developer </h2>
              <div className="content">
                <p>
                  This is our comprehensive Backend Developer training.
                </p>
              </div>
            </Link>
          </article>
          
          <article className="style5">
            <span className="image">
              <img src={pic5} alt="" />
            </span>
            <Link to="/courses/DevOpsTrng">
              <h2>DevOps Engineer </h2>
              <div className="content">
                <p>
                This is our comprehensive DevOps Engineer training.
                </p>
              </div>
            </Link>
          </article>
        </section>
        </div>
        <div className="inner box">
          <PricingTable></PricingTable>
        </div>
      </div>
    </div>
  </Layout>
);

export default SoftwareTrngPage;