import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
/* import ListGroup from 'react-bootstrap/ListGroup'; */

class PricingTable extends React.Component {
  render() {

    return (
      
    <Container align="justify">
        <Row>
            <Col>
            <Card  bg="info" text="black">
            <Card.Header as="h5">PRICING PLANS </Card.Header>
            </Card>
            </Col>
        </Row>
    </Container>
      
    )
  }
}
export default PricingTable
